'use strict';

import { IScope, ITimeoutService } from "angular";
import { Feature } from "geojson";
import * as L from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import { Settings } from "../../../../../data/account.data";
import { Emergency } from "../../../../../data/emergency.data";
import MapService from "../../../../../services/map.service";
import RestService from "../../../../../services/rest.service";
require("./mission.location.map.component.scss")


//alarmMap
export default class MissionLocationMapComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E'
    this.template = require('./mission.location.map.component.html');
    this.scope = {
      emergency: '='
    };
    this.controller = MissionLocationMapComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class MissionLocationMapComponentController {
  private DEFAULT_ZOOM = 16;
  public $timeout: ITimeoutService;
  public $scope: IScope;
  public map: L.Map;
  public emergency: Emergency;
  public mapService: MapService;
  public mapId: string;

  constructor($scope: IScope, $document, dataService, $timeout: ITimeoutService, mapService: MapService, private restService: RestService) {
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.mapService = mapService;
    this.mapId = `mymap-${uuidv4()}`;

    // Init map
    $document.ready(() => {
      L.Icon.Default.imagePath = '/img/static';
      // Default center
      let settings = dataService.getAccount().settings as Settings;

      this.map = L.map(this.mapId, {
        center: [settings.lat, settings.lng],
        zoom: this.DEFAULT_ZOOM
      });

      this.map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');
      this.initLayers();
      this.updateMap();
    });
  }


  /**
   * Loads the GEOJson
   */
  updateMap() {
    const hasCoords = this.emergency.lat && this.emergency.lng;
    if (hasCoords) {
      this.map.setView([this.emergency.lat, this.emergency.lng]);
    }
    this.$timeout(() => {
      this.map.invalidateSize(true);
      // Load geoJson
      this.restService.getLocationMapGeoJSONforEmergency(this.emergency).then(geojson => {
        const geoJSONLayer = L.geoJson(geojson, {
          style: this.styleFeature,
          pointToLayer: this.pointToLayer,
          onEachFeature: this.onEachFeature
        }).addTo(this.map);
        if (!hasCoords && geoJSONLayer) {
          this.map.fitBounds(geoJSONLayer.getBounds());
        }
      });
    }, 300);
  };

  onEachFeature(feature: Feature, layer: L.Layer) {
    layer.bindPopup(`<div class="mission-location-map-popup">
    <span class="mission-location-map-note">${feature.properties.note}</span>
    <span class="mission-location-map-source">${feature.properties.sourceName}</span>
    </div>`);
  }

  /**
   * Handles conversion from point feature to marker
   * @param feature 
   * @param latlng 
   * @returns 
   */
  pointToLayer(feature: Feature, latlng: L.LatLng) {
    switch (feature.properties.type) {
      case 'DANGEROUS_GOODS': {
        let icon = new L.Icon({
          iconAnchor: [16, 16],
          iconSize: [32, 32],
          iconUrl: `img/static/classes/danger.png`
        });
        let marker = L.marker(latlng, { icon: icon });
        marker.on('mouseover', (ev) => {
          ev.target.openPopup();
        });
        return marker;
      }
      case 'CIRCLE': {
        if (feature.properties.type === 'CIRCLE') {
          // Add circle to layer
          let circle = new L.Circle(new L.LatLng((feature.geometry as any).coordinates[1], (feature.geometry as any).coordinates[0]));
          circle.setRadius(Number.parseInt(feature.properties.radius));
          circle.setStyle({
            fillColor: feature.properties.color,
            fillOpacity: 0.2,
            opacity: 1,
            color: feature.properties.color,
            weight: 2,
            fill: true
          } as L.PathOptions);
          circle.on('mouseover', (ev) => {
            ev.target.openPopup();
          });
          return circle;
        }
      }
      case 'POINT': {
        let icon = new L.Icon({
          iconAnchor: [32, 32],
          iconSize: [64, 64],
          popupAnchor: [0, 0],
          iconUrl: `img/static/taktische_zeichen/${feature.properties.icon}`
        });
        let marker = L.marker(latlng, { icon: icon });
        marker.on('mouseover', (ev) => {
          ev.target.openPopup();
        });
        return marker;
      }
    }
  }

  /**
   * Handles converion between other features to layer
   * @param feature 
   * @returns 
   */
  styleFeature(feature: Feature) {
    switch (feature.properties.type) {
      case 'POLYLINE': {
        return {
          color: feature.properties.color
        }
      } case 'POLYGON': {
        return {
          color: feature.properties.color,
          fill: feature.properties.color
        }
      }
    }
  }

  initLayers() {
    // we have to remove the default OSM layer
    this.map.eachLayer(layer => {
      this.map.removeLayer(layer)
    })
    let layers = this.mapService.getBaseLayers();
    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    let mapService = this.mapService
    this.map.on('baselayerchange', function (e) {
      mapService.saveLayer(e.name);
    });
  }

}
